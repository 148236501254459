<template>
  <div class="bareMetal-terminal-box">
    <TerminalBody ref="terminalBody" box-class="bareMetal-terminal-box" :height="terminalHeight" :login-type="'Bare|'+ loginType" :base64="terminalBase64" path="/sshSocket/ws/vnc/ssh/terminal" />
  </div>
</template>

<script>
import TerminalBody from '@/components/TerminalBody.vue'
export default {
  components: {
    TerminalBody
  },
  data() {
    return {
      hostname: '',
      username: '',
      nodeName: '',
      terminalBase64: '',
      terminalHeight: '95%',
      loginType: ''
    }
  },
  mounted() {
    this.terminalBase64 = this.$store.state.core.bareSSHBase64
    this.loginType = this.$store.state.core.bareSSHLoginType
    this.$nextTick(() => {
      this.$refs.terminalBody.init()
    })
  },
  beforeUnmount() {

  },
  methods: {

  }
}
</script>
<style scoped>
.bareMetal-terminal-box{
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
